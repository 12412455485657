//rails_ujs
require('@rails/ujs').start()

//lodash
require('lodash')

//global assets to reference with <%= image_pack_tag %> & <%= asset_pack_path %> from views.
require.context('assets/images', true)

//global css
require('../assets/css/icons.css')
require('../assets/css/app-modern.css')
require('../assets/css/autosize.css')

//global js
import 'script-loader!../assets/js/appointments/date_time_picker.js';
import 'script-loader!../assets/js/autosize.js'
import 'script-loader!../assets/js/input_other.js';
